<style lang="sass" scoped>
.account-search-container
  height: calc(100vh/2)
  overflow-y: scroll
  padding-bottom: 100px
  clear: both
  ul
    padding: 0
    &.matched
      background-color: #f0f0f0
    li
      font-size: 1rem
      padding: 0.5rem
      &:hover
        background-color: #f0f0f0
        cursor: pointer
.btn-close
  position: relative
  top: -47px
  margin-bottom: -46px
  line-height: 32px
  padding-left: 15px
  padding-right: 15px
.selected
  background-color: #e4e4e4
</style>

<template lang="pug">
#account-search
  input.form-control.form-control-lg.rounded-0.text-center(
    ref='query'
    type='text'
    placeholder='이름, 번호4자리, 이메일, 멤버십'
    v-model='query'
    @input='query = $event.target.value'
    @keyup.down.prevent='selectDown()'
    @keyup.up.prevent='selectUp()'
    @keyup.enter.prevent='selectEnter()'
    @keyup='doFilter'
  )
  button.btn.float-right.btn-close(type='button' @click='clear()') ×
  div.account-search-container
    ul(:class='{matched: (filtered_accounts.length===1)}')
      li(
        v-for='(account, index) in filtered_accounts'
        @click='select({id: account.id})'
        v-bind:key='account.id'
        :class=`{'selected': (selected_index == index)}`
      )
        span.float-left {{account.customer_code}}
        span.float-right {{account.email}}
        span.float-right.mr-2 {{account.name}}
        span.float-right.mr-2 {{account.phone}}
</template>

<script>
export default {
  name: 'AccountSearch',
  props: {
    fillQueryName: String,
    fillQueryPhone: String,
    fillQueryEmail: String,
    onDismiss: Function,
    onSelect: Function,
  },
  computed: {
    // accounts() {
    //   return this.$store.state.accounts
    // },
    // session() {
    //   return this.$store.state.session
    // },
  },
  data() {
    return {
      query: '',
      filtered_accounts: [],
      property_id: 0,
      selected_index: -1,
      accounts: [
        {
          id: 1000,
          name: '',
          email: 'jin@gmail.com',
        }
      ]
    }
  },
  async mounted() {
    try {
      // console.log(this.$route.params.property_id)
      this.property_id = this.$route.params.property_id
      this.query = this.fillQueryName
      // this.filtered_accounts = [].concat(this.accounts).slice(0, 1000)
      // const r = await this.$http.post(`/v1/property/${property_id}/search_membership`, {
      //   fullname_search: this.fillQueryName || '',
      //   phone_last4: String(this.fillQueryPhone || '').slice(-4),
      //   email_search: this.fillQueryEmail || '',
      //   membership_id_search: this.fillQueryMembership,
      // })
      // this.filtered_accounts = [].concat(this.accounts).slice(0, 1000)
      // this.filtered_accounts = r.data.rows
      setTimeout(() => {
        this.$refs.query?.focus()
      }, 300)
      if (this.fillQueryName || this.fillQueryPhone || this.fillQueryEmail) {
        // this.doFilter()
        console.log('> init')
        const r = await this.$http.post(`/v1/property/${this.property_id}/search_membership`, {
          fullname_search: this.fillQueryName || '',
          phone_last4: String(this.fillQueryPhone || '').slice(-4),
          email_search: this.fillQueryEmail || '',
          membership_id_search: this.fillQueryMembership || '',
        })
        console.log('> init', r.data.rows)
        this.filtered_accounts = r.data.rows
      }
    } catch (error) {
      console.log(error)
      this.$modal.show('dialog', {
        title: '알림',
        text: error.message,
      })
    }
  },
  methods: {
    clear() {
      this.query = ''
    },
    dismiss() {
      this.query = ''
      this.onDismiss()
    },
    select(item) {
      this.query = ''
      this.onSelect(item)
    },
    selectDown() {
      console.log('down')
      // this.selected_index = Math.min(this.selected_index+1, this.filtered_accounts.length)
    },
    selectUp() {
      console.log('up')
      // this.selected_index = Math.max(this.selected_index-1, 0)
    },
    selectEnter() {
      console.log('enter')
      if (this.filtered_accounts.length === 1) {
        const [item] = this.filtered_accounts
        this.select(item)
      }
      // if (this.selected_index) {
      //   const [item] = this.filtered_accounts[this.selected_index]
      //   this.select(item)
      // }
      return false
    },
    async doFilter() {
      console.log('doF')
      let q = String(this.query).trim()
      if (q.length === 0) {
        const r = await this.$http.post(`/v1/property/${this.property_id}/search_membership_latest`)

        this.filtered_accounts = r.data.rows
        return
      }

      const r = await this.$http.post(`/v1/property/${this.property_id}/search_membership`, {
        fullname_search: this.query || '',
        phone_last4: String(this.query || '').slice(-4),
        email_search: this.query || '',
        membership_id_search: this.query,
      })

      this.filtered_accounts = r.data.rows
      // this.filtered_accounts = [].concat(r.data.rows).filter(e => {
      //   let matched_id, matched_email, matched_name = false
      //   if (q.length >= 1) {
      //     matched_id = String(e.customer_code).match(new RegExp(q, 'im'))
      //   }
      //   if (q.length >= 2) {
      //     matched_email = e.email.replace(/\.|@/g, '').match(new RegExp(q, 'im'))
      //   }
      //   if (q.length >= 1) {
      //     matched_name = e.name.replace(/\s/g, '').match(new RegExp(q, 'im'))
      //   }
      //   return matched_id || matched_email || matched_name
      // })
    }
  },
}
</script>
