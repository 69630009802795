<style lang="sass" scoped>
$primary: #007bff

.mtn-1
  margin-top: -1px
  z-index: 100
.item-order
  cursor: pointer
  &:hover
    opacity: 0.9

.order-border
  border-left: solid 5px #f0f0f0 !important
.order-border-deep
  border-left: solid 5px #888 !important
.order-success
  border-left: solid 5px #28a745 !important
.order-warning
  border-left: solid 5px #ffc107 !important
.order-danger
  border-left: solid 5px #dc3545 !important

.order-moved
  // opacity: 0.7
  background-color: #f0f0f0 !important
.tabs-status-container
  position: sticky
  top: 0px
  // background-color: #f0f0f0
  z-index: 3
  height: 50px

  box-shadow: 0px 1px 1px #d4d4d4

.tabs-status-flow
  min-width: 100px
  width: inherit
  // color: tomato
  // width: 100px
  padding: 8px 1rem
  border-top: solid #f0f0f0 3px
  line-height: 15px
  float: left
  height: 50px

  .router-link-active &
    background-color: #fff !important
    // border: solid 1px #ccc
    border-top: solid #466EC3 3px
    // border-right: solid #d4d4d4 1px
    // border-left: solid #d4d4d4 1px
    // border-width: 0 1px 1px 1px
    box-shadow: 0px -2px 5px #ccc
    position: relative

.input-field-overlay
  margin-top: -1.7rem
  float: left
  position: relative
  top: 1.7rem
  left: 0.5rem
.form-field
  border: 0
  border-bottom: solid 1px #f0f0f0
  &:focus
    color: $primary
    box-shadow: none !important
    border-bottom: solid 1px #ccc
// .item-hover-action-container
//   .item-hover-action
//     // display: none
.item-hover-action
  &:hover
    text-decoration: underline
    cursor: pointer
    // display: block !important
// .item-response-hover-action-container
//   .item-response-hover-action
//     // display: none
.item-response-hover-action
  &:hover
    text-decoration: underline
    cursor: pointer
.text-body
  text-overflow: ellipsis
  // white-space: pre
  word-wrap: nowrap
  height: 1.5rem
  overflow: hidden

</style>
<template lang="pug">
div.bg-light(style='margin-left: 70px')
  .tabs-status-container.bg-light(v-if='status_flows_counts_by_status')
    div
      router-link.link-status-flow(:to='`/property/${property_id}/order/all`')
        .tabs-status-flow.bg-light
          strong.text-muted(style='font-size: 1.2rem') {{status_flows_counts_total | number}}
          br
          small: strong  전체
    div(v-for='st in status_flows')
      router-link(:to='`/property/${property_id}/order/${st}/`' v-slot="{ href, navigate, isActive}")
        a(:href='href' @click='navigate' :class="{'router-link-active':(current_path.startsWith(href))}")
          .tabs-status-flow.bg-light
            //- span.badge.shadow.shadow-lg.text-white.ml-1(style='box-shadow: 0px 0px 10px -2px tomato; font-size: 12px; position: relative; margin-bottom: -1px' v-show='status_flows_counts_color_by_status[st]') {{status_flows_counts_color_by_status[st] | number}}
            strong.text-muted(style='font-size: 1.2rem') {{status_flows_counts_by_status[st] | number}}
            br
            small: strong {{st}}
            span.badge.text-white.ml-1.float-right(style='background-color:tomato; border: solid 1px rgba(0,0,0,.15); box-shadow: 0px 0px 10px -2px tomato; font-size: 12px; position: relative; top: -18px; right: -8px' v-show='status_flows_counts_color_by_status[st]') ON
              //- | {{status_flows_counts_color_by_status[st]*30 | number}}
            //- br
            //- span.badge.shadow.shadow-lg.text-white.float-right(style='background-color:tomato; border: solid 1px rgba(0,0,0,.15); font-size: 12px; position: relative; margin-top: -24px; right: 5px' v-show='status_flows_counts_color_by_status[st]') {{status_flows_counts_color_by_status[st] | number}}

  .clearfix(style='clear: both')
    keep-alive
      router-view(:key='`/orders/${sf_id}`')

  button.d-none(v-shortkey="['1']" @shortkey='shortkey_sf(1)')
  button.d-none(v-shortkey="['2']" @shortkey='shortkey_sf(2)')
  button.d-none(v-shortkey="['3']" @shortkey='shortkey_sf(3)')
  button.d-none(v-shortkey="['4']" @shortkey='shortkey_sf(4)')
  button.d-none(v-shortkey="['5']" @shortkey='shortkey_sf(5)')
  button.d-none(v-shortkey="['6']" @shortkey='shortkey_sf(6)')
  button.d-none(v-shortkey="['7']" @shortkey='shortkey_sf(7)')
  button.d-none(v-shortkey="['8']" @shortkey='shortkey_sf(8)')
  button.d-none(v-shortkey="['9']" @shortkey='shortkey_sf(9)')

  //- pre {{orders}}

  modal(name='membership-search' :height='`50%`' @before_close='membership_search_before_close($event)')
    membership-search(:on-dismiss='search_did_dismiss' :on-select='search_did_select' :fillQueryName='order.name')



</template>

<script>

import moment from 'moment'
import MembershipSearch from '@/components/MembershipSearch.vue'

export default {
  name: 'index',
  props: ['property_id', 'sf_id'],
  components: {
    MembershipSearch,
  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
    status_flows() {
      return this.$store.state.status_flows
    },
    status_flows_counts_by_status() {
      return this.$store.state.status_flows_counts_by_status
    },
    status_flows_counts_color_by_status() {
      return this.$store.state.status_flows_counts_color_by_status
    },
    status_flows_counts_total() {
      return this.$store.state.status_flows_counts_total
    },
    current_path() {
      return decodeURIComponent(this.$route.path)
    },
  },
  async mounted() {
    await this.$store.dispatch('users by id', this.property_id)
    await this.load()
    // this.activate_tab(0)
    // this.search_customer_code()
    // this.download_import()

    // if (this.$route.hash?.includes('#ORD')) {
    //   this.open_order({
    //     id: +this.$route.hash.replace('#ORD-', '') || 0,
    //   })
    // }
  },
  watch: {
    // '$route'(to, from) {
    //   console.log(to)
    //   if (to.hash?.includes('#ORD')) {
    //     this.open_order({
    //       id: +to.hash.replace('#ORD-', '') || 0,
    //     })
    //   } else {
    //     this.close_order()
    //   }
    // },
  },
  data() {
    return {
      tab: 0,
      loading: false,
      property: {},

      order_loading: false,
      orders: {

      },
      order_by_id: {},
      order_count: {},
      order_count_all: 0,
      active_order_id: 0,

      form: {
        sms: '',
        subject: '제목',
        email: '메일 본문',
        memo: '',
      },

      current_order_id: 0,
      order: {
        id: 0,
      },

      import_data: '',

      membership_search_closed: false,

      columns: [
        {
          label: 'id',
          field: 'id',
        },
        {
          label: 'status_text',
          field: 'status_text',
        },
        {
          label: 'created_at',
          field: 'created_at',
        },
        {
          label: 'name',
          field: 'name',
        },
        {
          label: 'phone',
          field: 'phone',
        },
        {
          label: 'email',
          field: 'email',
          sortable: false,
        },
        {
          label: 'customer_memo',
          field: 'customer_memo',
          sortable: false,
        },
        {
          label: 'order_memo',
          field: 'memo',
          sortable: false,
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],

      all_columns: [
        {
          label: 'id',
          field: 'id',
        },
        {
          label: 'status_text',
          field: 'status_text',
        },
        {
          label: 'created_at',
          field: 'created_at',
        },
        {
          label: 'name',
          field: 'name',
        },
        {
          label: 'phone',
          field: 'phone',
        },
        {
          label: 'email',
          field: 'email',
          sortable: false,
        },
        {
          label: 'customer_memo',
          field: 'customer_memo',
          sortable: false,
        },
        {
          label: 'order_memo',
          field: 'memo',
          sortable: false,
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      all_rows: [],
    }
  },
  methods: {
    shortkey_sf(order) {
      // no status button there
      if (this.status_flows.length < order-1) return

      // modal opened
      if (document.querySelector('.modal-opened')) return

      if (order == 1) {
        this.$router.push({
          path: `/property/${this.property_id}/order/all`,
        })
      } else {
        console.log(order, this.status_flows)
        this.$router.push({
          path: `/property/${this.property_id}/order/${this.status_flows[order-2]}/`,
        })
      }
    },

    async load() {
      try {
        // const r = await this.$http.get(`/v1/property/${this.property_id}`)
        // this.property = r.data.property
        // this.form.sms = `[${this.property.name}]`
        // console.log(this.property)
        // this.status_flows = this.property.status_flow_template.split(',')

        // await this.$store.dispatch('status flows', this.property_id)


        // await this.load_order_count()
        await this.$store.dispatch('status flows count', this.property_id)
        this.loading = true
      } catch (error) {
        console.log({error})
      }
    },

    async load_order_count() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/orders/count`)
        if (r?.data?.message != 'ok') throw new Error('가져오기 실패')


        const counts = r.data.counts
        const counts_by_status = {}
        for (const c of counts) {
          this.order_count[c.status_text] = c.count
        }

        this.order_count_all = r.data.total_count

        this.order_loading = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async load_order_latest() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/orders/latest`, {
          // params: {
          //   status,
          // }
        })
        if (r?.data?.message != 'ok') throw new Error('가져오기 실패')


        const rows = r.data.rows.map(e => {
          e.history = []
          e.loaded = false

          this.order_by_id[e.id] = e

          return e
        })

        const counts = r.data.counts
        // const counts_by_status = {}
        // for (const c of counts) {
        //   counts_by_status[c.status_text] = c.count
        // }


        // for (const status of this.status_flows) {
        //   if (!this.orders[status]) {
        //     // this.orders[status] = []
        //     this.$set(this.orders, status, [])
        //   }
        //   this.orders[status] = rows.filter(e => {
        //     return e.status_text == status
        //   })

        //   this.order_count[status] = counts_by_status[status]
        // }
        this.order_count_all = counts.count
        this.orders_latest = rows

        this.order_loading = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async activate_tab(newTabIndex){
      // console.log('sdfsd', newTabIndex)
      // this.order_loading = false

      if (newTabIndex == 0) {
        // await this.load_order_latest()
        // this.rows = this.orders_latest
        this.$router.push({
          path: `/property/${this.property_id}/order/all`,
        })
      } else {
        const status_flow = this.status_flows[newTabIndex-1]
        // await this.load_order(status_flow)
        // this.rows = this.orders[status_flow]
        this.$router.push({
          path: `/property/${this.property_id}/order/${status_flow}/`,
        })
      }
    },

    route_open_order(order) {

      // if (this.active_order_id == order.id) {
      //   this.$router.push({
      //     hash: '',
      //   })
      // } else {
      //   this.$router.push({
      //     hash: 'ORD-'+order.id,
      //   })
      // }
      this.$router.push({
        path: `/property/${this.property_id}/orders/${order.id}`,
      })
    },
    open_order(order) {
      this.active_order_id = (this.active_order_id == order.id) ? 0 : order.id

      this.load_order_detail(order)
    },
    route_close_order(order) {
      this.$router.push({
        hash: '',
      })
    },
    close_order() {
      this.active_order_id = 0
    },

    async load_order_detail(order_id) {
      try {
        // const order = this.order_by_id[_order.id]
        // console.log('>>', order)
        const r = await this.$http.get(`/v1/property/${this.property_id}/orders/${order_id}`)
        if (r?.data?.message != 'ok') throw new Error('주문상세 가져오기 실패')

        console.log(r.data)
        const order = r.data.order

        order.history = r.data.order_history.map(e => {
          e.editing = false
          e.created_ts = moment(e.created_at).unix()
          return e
        })
        console.log('>>>>>>', order)
        this.order = order
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    search_customer_code() {
      this.$modal.show('membership-search', {})
    },
    search_did_dismiss() {
      console.log('search_did_dismiss')
    },
    search_did_select(item) {
      console.log('search_did_select', item)
      // const prev_ids = this.current_secret.accounts.map(e => e.id)
      // const next_ids = prev_ids.concat(item.id)
      // this.current_secret.accounts = uniq(next_ids).map( id => {
      //   return this.accounts_by_id[id]
      // })
      this.$modal.hide('membership-search')
    },


    async set_status(order, st) {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/${order.id}/set_status`, {
          status_text: st,
        })
        if (r?.data?.message != 'ok') throw new Error('상태변경 실패')

        order.status_text = st

        this.$modal.show('dialog', {
          title: '알림',
          text: '변경했습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async open_modal(order_id) {
      console.log('open_modal', order_id)
      // loading
      // this.current_order_id = order_id
      // await this.load_order_detail(order_id)
      this.$modal.show('order-detail')
    },
    close_modal(event) {
      console.log('close_modal')
      this.$modal.hide('order-detail')
    },
    before_close(event) {
      // if (!this.membership_search_closed) {
      //   return event.cancel()
      // }
      this.$router.push({
        path: `/property/${this.property_id}`,
      })
    },
    opened() {
      this.$refs['order-input']?.focus()
    },


    get_sms_result_text(code) {
      return {
        '200': '수신완료',
      }[code] || '발송실패'
    },

    membership_search_before_close($evente) {
      this.membership_search_closed = true
      console.log('membership_search_before_close', $event)
    }
  },
}
</script>
